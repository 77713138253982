<template>
  <b-form-group
    label="Session"
    label-for="session"
  >
    <v-select
      id="session"
      label="topic"
      :value="session"
      :reduce="session => session.id"
      :options="sessionsOptions"
      :disabled="disabled"
      @input="onSessionChange"
    />
  </b-form-group>
</template>
<script>
import vSelect from 'vue-select'

export default {
  name: 'SelectSession',
  components: {
    vSelect,
  },
  props: {
    session: { type: Number, default: null },
    event: { type: Number, default: null },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      sessionsOptions: [],
    }
  },
  watch: {
    event() {
      this.onSessionChange(null)
      this.getSessions()
    },
  },
  methods: {
    getSessions() {
      this.$activities.get(`/internalops/upcoming-events/event/${this.event}/sessions/filter`).then(res => {
        this.sessionsOptions = res.data.data
      })
    },
    onSessionChange(session) {
      this.$emit('update:session', session)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
