<template>
  <validation-observer #default="{handleSubmit}">
    <b-form @submit.prevent>
      <text-input
        id="reason"
        name="Reason"
        rules="required"
        :text.sync="reason"
      />
      <submit-button v-bind="{handleSubmit,submit}" />
    </b-form>
  </validation-observer>
</template>
<script>
import bookingStatus from '@/common/compositions/QuickEvents/bookingStatus'
import TextInput from '../../common/FormInputs/TextInput.vue'
import SubmitButton from '../../common/FormInputs/SubmitButton.vue'

export default {
  name: 'ReasonModal',
  components: { TextInput, SubmitButton },
  props: {
    actionData: { type: Object, default: () => {} },
  },
  data() {
    return {
      reason: '',
    }
  },
  setup() {
    const { approveBooking, rejectBooking, cancelBooking } = bookingStatus()

    return {
      approveBooking, rejectBooking, cancelBooking,
    }
  },
  methods: {
    submit() {
      const actionName = this.actionData.actionType
      this[actionName](this.actionData.item, this.reason).then(() => {
        this.$bvModal.hide('reason-modal')
      })
    },
  },
}
</script>
<style lang="scss">

</style>
