<template>
  <div>
    <b-card>
      <b-row>
        <!-- Select Event -->
        <b-col md="6">
          <select-event :event.sync="event" />
        </b-col>

        <!-- Select Session -->
        <b-col md="6">
          <select-session
            :disabled="!event"
            :session.sync="session"
            :event="event"
          />
        </b-col>

        <b-col md="6">
          <custom-vue-select
            id="status"
            label="Status"
            placeholder="Status"
            :value-reducer="option=>option.value"
            :options="[
              {name:'All',value:null},
              {name:'Pending',value:'pending'},
              {name:'Approved',value:'approved'},
              {name:'Canceled',value:'canceled'},
              {name:'Rejected',value:'rejected'},
            ]"
            :selected.sync="bookingStatus"
            text-field="name"
          />
        </b-col>
        <!-- Search Button -->
        <b-col
          md="2"
          class="mt-2 mt-md-1"
        >
          <search-button
            v-b-tooltip.hover
            :title="buttonTooltip"
            :disabled="!(event&&session)"
            @refresh="refreshTable"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- Table -->
    <bookings-table
      ref="parentTable"
      v-bind="{pagination,getBookings,actionsEnabled}"
      :action-data.sync="actionData"
    />
    <!-- Reason Popup -->
    <b-modal
      id="reason-modal"
      centered
      title="Reason"
      hide-footer
    >
      <reason-modal :action-data="actionData" />
    </b-modal>
    <!-- Pagination -->
    <pagination
      :per-page="pagination.perPage"
      :total-rows="pagination.totalRows"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>
<script>
import BookingsTable from '@/common/components/QuickEvents/Bookings/BookingsTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import SelectEvent from '@/common/components/QuickEvents/Bookings/SelectEvent.vue'
import SelectSession from '@/common/components/QuickEvents/Bookings/SelectSession.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import ReasonModal from '@/common/components/QuickEvents/Bookings/ReasonModal.vue'
import paginationData from '@/common/compositions/common/paginationData'
import CustomVueSelect from '@/common/components/common/FormInputs/CustomVueSelect.vue'

export default {
  name: 'BookingsList',
  components: {
    BookingsTable,
    Pagination,
    SelectEvent,
    SelectSession,
    SearchButton,
    ReasonModal,
    CustomVueSelect,
  },
  data() {
    return {
      actionData: {},
      event: null,
      bookingStatus: null,
      session: null,
      actionsEnabled: false,
    }
  },
  computed: {
    buttonTooltip() {
      return this.event && this.session ? '' : 'You need to select event and session first'
    },
  },
  setup() {
    const { pagination } = paginationData()
    return { pagination }
  },
  methods: {
    getBookings() {
      if (this.session) {
        return this.$activities.get(`/internalops/bookings/session/${this.session}`, { params: { page: this.pagination.currentPage, bookingStatus: this.bookingStatus } }).then(res => {
          const { bookings } = res.data.data
          this.pagination.totalRows = res.data.pagination.total
          this.actionsEnabled = this.$moment().isBefore(res.data.data.sessionEndDate)
          return bookings || []
        }).catch(() => [])
      }
      return []
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },

}
</script>
<style lang="scss">

</style>
